import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import CallAnytime from '../../myaccount_header/call-anytime'

const PasswordResetMobileCompletedPage = ({t}) => {
    return (
        <div>
            <div className="container py-3">
                <CallAnytime />

                <div className="row">
                    <div className="col-lg-6 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Your password has been reset")}
                        </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">
                                <div className="text-muted mb-2">{t("Success! You may log into the mobile app with your new password.")}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

PasswordResetMobileCompletedPage.propTypes = {
    t: PropTypes.func
}

export default withTranslation('forgotPassword')(PasswordResetMobileCompletedPage)
