import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import PasswordResetValidateNewPassword from './password-reset-validate-new-password'
import {
    confirmPasswordReset, completePasswordReset, submitLoginForm
} from '../../../actions/auth/login/login-actions'
import { withRouter } from '../../../srp_modules/with-router'

class PasswordResetValidateNewPasswordContainer extends React.Component {
    constructor(props) {
        super(props)

        let initialState = {
            email: '',
            token: '',
            tokenValidated: false,
            mobile: false
        }

        let params = queryString.parse(location.search)
        if (params !== undefined && params.e !== undefined && params.t !== undefined) {
            let email = params.e
            let authToken = params.t
            let mobile = params.c === 'mobile'

            initialState = { ...initialState, email, token: authToken, mobile }
        }

        this.state = initialState
    }

    componentDidMount() {
        this.redirectToLoginIfInvalidToken()
    }

    async redirectToLoginIfInvalidToken() {
        // HACK: It would be better to move "tokenValidated" into redux state
        if (this.state.email.length > 0 && this.state.token.length > 0 && this.state.tokenValidated === false) {
            let tokenValidated = await this.props.confirmPasswordReset(this.state.email, this.state.token)
            if (tokenValidated && !this.state.tokenValidated)
                this.setState({ tokenValidated }) // eslint-disable-line react/no-did-mount-set-state
        }
        else {
            this.props.router.navigate('/login', { replace: true })
        }
    }

    render() {
        return this.state.tokenValidated === true
        ? (<PasswordResetValidateNewPassword
                completePasswordResetSubmit={this.props.completePasswordResetSubmit(this.state.email, this.state.token, this.state.mobile, this.props.t)}
                t={this.props.t}
        />) : null
    }
}

const mapStateToProps = state => {
    return { ...state.login }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        confirmPasswordReset: async (e, t) => {
            let result = await dispatch(confirmPasswordReset(e,t))
            if (result === undefined)
                return false

            if (result.error === true || result.payload.success === false) {
                ownProps.router.navigate('/login', { replace: true })
                return false
            }

            return true
        },
        completePasswordResetSubmit: (email, token, fromMobileApp, t) => async (values, formikGoodies) => {
            let response = await dispatch(completePasswordReset(email, token, values.password))

            if (response.error === true || response.payload !== true) {
                formikGoodies.setStatus({ error:
                    <div className="srp-alert-error">
                        {t("something_went_wrong")}
                    </div>})
                formikGoodies.setSubmitting(false)
                return
            }

            if (fromMobileApp) {
                // If coming from mobile app, display a message similar to the following:
                // "Success! You may log into the mobile app with your new password."
                // Side note: Do we still want to log them in and display it as a dashboard message?
                ownProps.router.navigate('/passwordreset/complete')
            }
            else {
                // The app root handles replacing the history with the dashboard if login is successful
                dispatch(submitLoginForm(email, values.password))
                ownProps.router.navigate('/myaccount/dashboard', { replace: true })
            }
        }
    }
}

PasswordResetValidateNewPasswordContainer.propTypes = {
    authTokenToCompleteReset: PropTypes.string,
    completePasswordResetSubmit: PropTypes.func.isRequired,
    confirmPasswordReset: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    t: PropTypes.func
}

export default withTranslation('forgotPassword')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetValidateNewPasswordContainer)))

