import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18n from '../i18n'
import config from 'my-account-config'

const initialState = {
    startDate: "",
    endDate: "",
    title: "",
    message: ""
}

export const publicConfigApi = createApi({
    reducerPath: 'publicConfig',
    initialState,
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiBaseUrl
    }),
    endpoints: build => ({
        getConfigInfo: build.query({
            query: () => 'public_config.json',
            transformResponse: (response) => {
                if (!response.emergencyBanner)
                    return response

                let banner = response.emergencyBanner
                // NOTE: Side effect; is this okay to do here?
                i18n.addResourceBundle('en', 'emergencyBanner', {
                    [banner.title]: banner.title,
                    [banner.message]: banner.message
                })
                i18n.addResourceBundle('es', 'emergencyBanner', {
                    [banner.title]: banner.titleEs,
                    [banner.message]: banner.messageEs
                })

                return response
            }
        })
    })
})

export const { useGetConfigInfoQuery } = publicConfigApi