import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from 'my-account-config'
import * as loadingConstants from '../constants/loading-status-constants'
import { useLocation } from 'react-router-dom'
import LoginPageContainer from '../components/login_page/login-page-container'
import PayUsingBankAccountLoginPage from '../components/guest_payment_page/input_page/pay-by-bank-account-login-page'

const LoginRequiredContainer = (props) => {
    let location = useLocation()

    React.useEffect(() => {
        if (props.isLoggedIn && props.isIrrigator) {
            window.location = config.waterBaseUrl + 'dashboard'
            return
        }
    })

    let pathname = location.pathname.toLowerCase()
    // Per Garrett, redirect unauthenticated /myaccount/payment requests to the payment login form.
    // Use the regular login form to authenticate /myaccount/payment/gift because the gift payment
    // page doesn't look like the payment login form.
    let loginPage = pathname.startsWith('/myaccount/payment') && !pathname.startsWith('/myaccount/payment/gift')
                  ? <PayUsingBankAccountLoginPage /> : <LoginPageContainer />

    let renderMe = props.loginSubmitStatus === loadingConstants.LOADING_STATUS_SUCCESS
                   && props.isLoggedIn && !props.isIrrigator

    return renderMe ? props.children : loginPage
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.login.isLoggedIn,
        isIrrigator: state.login.isIrrigator,
        loginSubmitStatus: state.login.loginSubmitStatus
    }
}

LoginRequiredContainer.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isIrrigator: PropTypes.bool.isRequired,
    loginSubmitStatus: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default connect(mapStateToProps)(LoginRequiredContainer)