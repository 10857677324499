import React from 'react'
import PropTypes from 'prop-types'

import GreetingContainer from '../components/myaccount_header/greeting-container'
import AccountInfoBarContainer from '../components/myaccount_header/info_bar/account-info-bar-container'
import NavigationMenuContainer  from '../components/myaccount_header/navigation_menu/navigation-menu-container'

import PromotionalBannerContainer from '../components/myaccount_ads/promotional-banner-container'

import NavMenuButtonResponsiveContainer from '../components/myaccount_header/navigation_menu/nav-menu-button-responsive-container'

class MyAccountMainContainer extends React.Component {
    render() {
        // NOTE: Page title is now set in the routes file, but I'm leaving this here for
        // legacy reasons - it's still used to display the title in the responsive menu.
        const pageTitleEn = typeof(this.props.pageTitleEn)==="string" ? this.props.pageTitleEn : this.props.pageTitle

        return (
            <React.Fragment>
                <NavMenuButtonResponsiveContainer pageTitle={pageTitleEn} />
                <div className="container pb-3 ">
                    <GreetingContainer />
                    <AccountInfoBarContainer disableSelector={false} />
                    <NavigationMenuContainer />

                    {this.props.children}

                    <PromotionalBannerContainer />
                </div>
            </React.Fragment>
        )
    }
}

MyAccountMainContainer.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    pageTitleEn: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired
    ])
}

export default MyAccountMainContainer