import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { LANG_COOKIE_KEY, LANG_COOKIE_EXPIRATION_DAYS } from './constants/cookie-constants'
import { savePreferredLanguage, setPreventLocalization } from './actions/auth/myaccount/myaccount-actions'
import { Settings } from 'luxon'

const CheckLocalization = props => {
    // This component's purpose is to enforce that the display language is synchronized
    // with react-i18next, as well as the preventLocalization setting in Redux state.
    let languageRef = React.useRef(props.i18n.language)

    React.useEffect(() => {
        if (props.preventLocalization !== props.preventLocalizationFromRedux) {
            props.setPreventLocalization(props.preventLocalization)
        }

        if (props.preventLocalization === true) {
            if (props.i18n.language !== 'en') {
                props.setPreventLocalization(props.preventLocalization)
                props.i18n.changeLanguage('en')
            }
        }
        else if (props.i18n.language !== props.preferredLanguage) {
            if (languageRef.current !== props.i18n.language) {
                props.savePreferredLanguage(props.i18n.language)
                Cookies.set(LANG_COOKIE_KEY, props.i18n.language, { expires: LANG_COOKIE_EXPIRATION_DAYS })
            }
            else {
                props.i18n.changeLanguage(props.preferredLanguage)
                Cookies.set(LANG_COOKIE_KEY, props.preferredLanguage, { expires: LANG_COOKIE_EXPIRATION_DAYS })
            }
        }

        languageRef.current = props.i18n.language
        Settings.defaultLocale = props.i18n.language
    })

    return <>{props.children}</>
}

const mapDispatchToProps = dispatch => {
    return {
        savePreferredLanguage: preferredLanguage => {
            dispatch(savePreferredLanguage(preferredLanguage))
        },
        setPreventLocalization: preventLocalization => {
            dispatch(setPreventLocalization(preventLocalization))
        }
    }
}

const mapStateToProps = state => {
    return {
        preferredLanguage: state.myAccount.preferredLanguage,
        preventLocalizationFromRedux: state.myAccount.preventLocalization
    }
}

CheckLocalization.propTypes = {
    children: PropTypes.object.isRequired,

    i18n: PropTypes.object.isRequired,
    preferredLanguage: PropTypes.string.isRequired,
    preventLocalization: PropTypes.bool,
    preventLocalizationFromRedux: PropTypes.bool,

    savePreferredLanguage: PropTypes.func.isRequired,
    setPreventLocalization: PropTypes.func.isRequired
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CheckLocalization))