/* eslint-disable react/no-did-mount-set-state */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import ChangePricePlanAnon from './change-price-plan-anon'
import ChangePricePlanAuth from './change-price-plan-auth'
import ChangePricePlanConfirmation from './change-price-plan-confirmation'

import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AnalyticsFormTracking from '../common_formik/analytics-form-tracking'
import CallAnytime from '../myaccount_header/call-anytime'

import { getContacts } from '../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getRateCode, submitChangePricePlan } from '../../actions/auth/bill_account/bill-account-actions'
import { getEnrolledProgramsDetails } from '../../actions/auth/payment/payment-actions'
import { myAccountConstants } from '../../constants/myaccount-constants'
import { pricePlanConstants } from '../../constants/price-plan-constants'
import { contactConstants } from '../../constants/contact-constants'
import { withRouter } from '../../srp_modules/with-router'
import * as loadingStatus from '../../constants/loading-status-constants'

import format from '../../srp_modules/format'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class ChangePricePlanContainer extends React.Component {
    constructor(props) {
        super(props)
        this.createAndSubmitChangePricePlanForm = this.createAndSubmitChangePricePlanForm.bind(this)
    }

    componentDidMount() {
        this.checkForData()
    }

    componentDidUpdate() {
        this.checkForData()
    }

    checkForData() {
        if (!this.props.isLoggedIn)
            return

        if (this.props.selectedBillAccount === 0)
            return

        // BUG: This looks like it could cause the page to get in an infinite I/O loop if the API is down (it's possible React could intervene)
        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNameInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCurrentRateCode(this.props.selectedBillAccount)
        }

        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getEnrolledProgramsDetailsInfo(this.props.selectedBillAccount)
        }
    }

    async createAndSubmitChangePricePlanForm(values, formikActions) {
        let { accountDetails, accountType, selectedPricePlanCommercial, selectedPricePlanResidential } = values
        let isCommercial = accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
        let newPricePlan = isCommercial ? selectedPricePlanCommercial : selectedPricePlanResidential

        let pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.UNKNOWN
        if (isCommercial) {
            switch (newPricePlan) {
                case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.GENERAL_COMM
                    break
                case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_COMM
                    break
                case pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.UNKNOWN:
                default:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.UNKNOWN
                    break
            }
        }
        else {
            switch (newPricePlan) {
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_4_7:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.EZ3_4_7
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.BASIC:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.BASIC_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.MPOWER:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.MPOWER_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.GENERATION_RES
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_DEMAND:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_DEMAND
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.TOU_EXPORT
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.EV_EXPORT
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.AVERAGE_DEMAND
                    break
                case pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.UNKNOWN:
                default:
                    pricePlanDescription = pricePlanConstants.PRICE_PLAN_NAME.EN.UNKNOWN
                    break
            }
        }

        let changePricePlanForm = {
            isResidential: !isCommercial,
            accountNumber: accountDetails.billAccount,
            customerInfoModel: {
                firstName: accountDetails.firstName,
                middleName: accountDetails.middleInitial,
                lastName: accountDetails.lastName,
                emailAddress: accountDetails.email,
                phoneNumber: accountDetails.phone
            },
            businessInfoModel: {
                businessName: accountDetails.businessName,
                dbaName: accountDetails.dbaName,
                contactName: accountDetails.contactName,
                contactPosition: accountDetails.contactPosition,
                emailAddress: accountDetails.email,
                phoneNumber: accountDetails.phone
            },
            serviceAddress: {
                streetAddress: accountDetails.serviceAddressLineOne,
                streetAddressLineTwo: accountDetails.serviceAddressLineTwo,
                state: "AZ",
                city: accountDetails.city,
                zipcode: accountDetails.zipCode,
                addressType: 1 //1 = service address 0 = billing/mailing address
            },
            srpPlanOfferingModel: {
                planOffering: newPricePlan,
            },
            buisnessPlanOffering: {
                planOffering: newPricePlan,
            },
            selectedPlanText: pricePlanDescription,
            language: this.props.preferredLanguage === "es" ? "es-MX" : "en-US" 
        }

        const res = await this.props.submitChangePricePlanForm(changePricePlanForm)
        const submittedSuccessfully = (res.payload === true)
        // for AnalyticsFormTracking: emit an error event if there's an error calling the API
        formikActions.setStatus({submitError: !submittedSuccessfully})

    }

    getInitialValuesFromProps() {
        return {
            steps: pricePlanConstants.STEPS_ANON,
            activeStep: this.props.compareMyPricePlan.fromCmpp ? 1 : 0,
            pricePlanDetails: { },

            accountType: this.props.selectedBillAccountDetails.isCommercial
                ? pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                : pricePlanConstants.ACOUNT_TYPE.RESIDENTIAL,

            accountDetails: {
                billAccount: this.props.selectedBillAccount > 0 ? format.srpAccountNumber(this.props.selectedBillAccount) : '',
                firstName: (this.props.customerNames.firstName).length !== 0 ? this.props.customerNames.firstName : this.props.customerNames.displayName,
                middleInitial: this.props.customerNames.middleName,
                lastName: this.props.customerNames.lastName,
                businessName: (this.props.customerNames.firstName).length === 0 ? this.props.customerNames.displayName : '',
                serviceAddressLineOne: this.props.serviceAddress.streetAddress,
                city: this.props.serviceAddress.cityName,
                zipCode: this.props.serviceAddress.zipCode,
                email: this.props.primaryEmail,
                phone: getCustomerPhoneNumber(this.props.contacts)
            },
            accountDetailsChanged: false,

            selectedPricePlanResidential: this.props.compareMyPricePlan.fromCmpp
                ? mapBusinessOfferingToServerMap(this.props.compareMyPricePlan.selectedBusinessOffering, this.props.currentRateCode.isSolar)
                : pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU,
            selectedPricePlanCommercial: this.props.compareMyPricePlan.fromCmpp
                ? mapBusinessOfferingToServerMap(this.props.compareMyPricePlan.selectedBusinessOffering, this.props.currentRateCode.isSolar)
                : pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.UNKNOWN,
        }
    }

    getCardDetails(showConfirmation) {
        if (this.props.isLoggedIn) {
            if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
            }
        }

        const isSpanish = (this.props.i18n.language === "es")

        return (
            <Formik initialValues={this.getInitialValuesFromProps()} onSubmit={this.createAndSubmitChangePricePlanForm}>
                {({values, setFieldValue, handleSubmit}) => {
                    let setActiveStep = (index) => {
                        setFieldValue('activeStep', index)
                        document.getElementById("topOfCppForm").scrollIntoView()
                    }

                    let stepName = values.steps[values.activeStep].label
                    let meta = {
                        accountType: values.accountType,
                        selectedPricePlan: values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                                           ? values.selectedPricePlanCommercial
                                           : values.selectedPricePlanResidential
                    }

                    return (<React.Fragment>
                        <AnalyticsFormTracking name="Change Price Plan" formStep={stepName} meta={meta} />
                        {(showConfirmation)
                        ? <ChangePricePlanConfirmation
                                isLoggedIn={this.props.isLoggedIn}
                                isCommercial={values.accountDetails.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL}
                                changePricePlanStatus={this.props.changePricePlanStatus} />
                        : <div className="row">
                            { this.props.isLoggedIn
                                ? <ChangePricePlanAuth
                                    activeStep={values.activeStep}
                                    setActiveStep={setActiveStep}
                                    isLoggedIn={this.props.isLoggedIn}
                                    isCommercial={values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL}
                                    currentRateCode={mapBusinessOfferingToServerMap(this.props.currentRateCode.rateCode, this.props.currentRateCode.isSolar)}
                                    currentRateCodeDescription={isSpanish ? this.props.currentRateCode.descriptionEs : this.props.currentRateCode.description}
                                    selectedPricePlanResidential={values.selectedPricePlanResidential}
                                    selectedPricePlanCommercial={values.selectedPricePlanCommercial}
                                    selectNewPricePlanResidential={value => setFieldValue('selectedPricePlanResidential', value)}
                                    selectNewPricePlanCommercial={value => setFieldValue('selectedPricePlanCommercial', value)}
                                    accountDetails={values.accountDetails}
                                    currentPlanIsTouOrSolar={this.props.currentRateCode.isTimeOfUse || this.props.currentRateCode.isSolar}
                                    selectedPricePlan={values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                                        ? values.selectedPricePlanCommercial
                                        : values.selectedPricePlanResidential}
                                    createAndSubmitChangePricePlanForm={handleSubmit}
                                    changePricePlanStatus={this.props.changePricePlanStatus}
                                    fromCmpp={this.props.compareMyPricePlan.fromCmpp}
                                    backButtonClick={() => { this.props.router.navigate(-1) }} />
                                : <ChangePricePlanAnon
                                    accountType={values.accountType}
                                    setAccountType={value => setFieldValue('accountType', value)}
                                    activeStep={values.activeStep}
                                    setActiveStep={setActiveStep}
                                    accountDetails={values.accountDetails}
                                    setAccountDetails={value => setFieldValue('accountDetails', value)}
                                    accountDetailsChanged={values.accountDetailsChanged}
                                    setAccountDetailsChanged={() => setFieldValue('accountDetailsChanged', true)}
                                    isLoggedIn={this.props.isLoggedIn}
                                    isCommercial={values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL}
                                    currentRateCode={mapBusinessOfferingToServerMap(this.props.currentRateCode.rateCode)}
                                    currentRateCodeDescription={isSpanish ? this.props.currentRateCode.descriptionEs : this.props.currentRateCode.description}
                                    selectedPricePlanResidential={values.selectedPricePlanResidential}
                                    selectedPricePlanCommercial={values.selectedPricePlanCommercial}
                                    selectNewPricePlanResidential={value => setFieldValue('selectedPricePlanResidential', value)}
                                    selectNewPricePlanCommercial={value => setFieldValue('selectedPricePlanCommercial', value)}
                                    currentPlanIsTouOrSolar={this.props.currentRateCode.isTimeOfUse || this.props.currentRateCode.isSolar}
                                    selectedPricePlan={values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                                        ? values.selectedPricePlanCommercial
                                        : values.selectedPricePlanResidential}
                                    createAndSubmitChangePricePlanForm={handleSubmit}
                                    changePricePlanStatus={this.props.changePricePlanStatus}
                                    fromCmpp={this.props.compareMyPricePlan.fromCmpp}
                                    backButtonClick={() => { this.props.router.navigate(-1) }}
                                />
                            }

                            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                            <div className="srp-card-header">{this.props.t("Need help?")}</div>
                            <div className="srp-card-body" style={{ backgroundColor: '#f7dfd0' }}>
                                { values.accountType === pricePlanConstants.ACOUNT_TYPE.COMMERCIAL
                                    ? <div className="srp-card-details">
                                        <p className="d-lg-block d-none mb-0">
                                            {`${this.props.t("contact_business_contact_center")}
                                            ${myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD},
                                            ${this.props.t("Monday through Friday, 7 a.m.-5 p.m.")}`}
                                        </p>
                                        <p className="d-lg-none d-block mb-0">
                                            {`${this.props.t("contact_business_contact_center")} `}
                                            <a href={"tel:" + myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>
                                                {myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                            </a>
                                            {`, ${this.props.t("Monday through Friday, 7 a.m.-5 p.m.")}`}
                                        </p>
                                    </div>
                                    : <div className="srp-card-details">
                                        <p className="d-lg-block d-none mb-0">
                                            {`${this.props.t("contact_res_contact_center")}
                                            ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                            , ${this.props.t("available_24_hrs_7_days")}.`}
                                        </p>
                                        <p className="d-lg-none d-block mb-0">
                                            {`${this.props.t("contact_res_contact_center")} `}
                                            <a href={"tel:" + myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER}>
                                                {myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}
                                            </a>
                                            {`, ${this.props.t("available_24_hrs_7_days")}.`}
                                        </p>
                                    </div>
                                }
                            </div>
                            </div>
                        </div>}
                    </React.Fragment>)
                }}
            </Formik>
        )
    }

    render() {
        let showConfirmation = this.props.changePricePlanStatus === loadingStatus.LOADING_STATUS_SUCCESS
                             || this.props.changePricePlanStatus === loadingStatus.LOADING_STATUS_FAILURE

        return (
            <div>
                <div className="container py-3">
                    <CallAnytime />
                    <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{this.props.t("Change my price plan")}</h3>
                    <h3 className="d-lg-none d-block mt-3 mb-3">{this.props.t("Change my price plan")}</h3>

                    {this.props.isLoggedIn
                        ? <AccountInfoBarContainer disableSelector={!showConfirmation} />
                        : null
                    }

                    {this.getCardDetails(showConfirmation)}

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.login,
        ...state.accountInfo.billAccount,
        ...state.accountInfo.contact,
        ...state.accountInfo.payment,
        preferredLanguage: state.myAccount.preferredLanguage
     }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNameInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getCurrentRateCode: (billAccount) => {
            dispatch(getRateCode(billAccount))
        },
        getEnrolledProgramsDetailsInfo: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },
        submitChangePricePlanForm: async (form) => {
            return await dispatch(submitChangePricePlan(form))
        }
    }
}

ChangePricePlanContainer.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    isLoggedIn: PropTypes.bool,
    primaryEmail: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    preferredLanguage: PropTypes.string,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNameInfo: PropTypes.func,

    contacts: PropTypes.array,
    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,

    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    getServiceAddressInfo: PropTypes.func,

    currentRateCode: PropTypes.object,
    currentRateCodeStatus: PropTypes.string,
    getCurrentRateCode: PropTypes.func,

    enrolledProgramsDetails: PropTypes.object,
    enrolledProgramsDetailsStatus: PropTypes.string,
    getEnrolledProgramsDetailsInfo: PropTypes.func,

    compareMyPricePlan: PropTypes.object,

    changePricePlanStatus: PropTypes.string,
    submitChangePricePlanForm: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation('changePricePlan')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePricePlanContainer)))

function getCustomerPhoneNumber(contacts) {
    let phoneNumber = ""
    for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contactType === contactConstants.CONTACT_TYPE.PHONE) {
            phoneNumber = contacts[i].contactAddress
            return phoneNumber
        }
    }

    return phoneNumber
}

function mapBusinessOfferingToServerMap(businessOffering, isSolar) {
    switch (businessOffering) {
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_2_5:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_2_5
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EZ3_4_7:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EZ3_4_7
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.BASIC:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.BASIC
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.MPOWER
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.TOU_EXPORT:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_EXPORT
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.EV_EXPORT:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.EV_EXPORT
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.AVERAGE_DEMAND:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.AVERAGE_DEMAND
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.COMM_TOU:
            return pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.TOU
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.COMM_GENERAL:
            return pricePlanConstants.PRICE_PLAN_COMM_SERVER_MAP.GENERAL
        case pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.GENERATION: {
            if (isSolar) {
                return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.GENERATION
            }
            else {
                return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.TOU_DEMAND
            }
        }
        default:
            return pricePlanConstants.PRICE_PLAN_RES_SERVER_MAP.UNKNOWN
    }
}