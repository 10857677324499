import React from 'react'
import cookies from 'js-cookie'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'


import NavBarMenuContainer from './common_navigation/navbar-menu-container'
import FooterContainer from './common_footer/footer-container'
import MapScriptLoader from './map-script-loader'
import SrpSnackbar from './common_snackbar/srp-snackbar'
import PrimaryContactInfoReminderModal from './primary_contact_info/primary-contact-info-reminder-modal'
import MaintenanceTestBanner from './common_navigation/maintenance-test-banner'

const MainLayout = (props) => {
    let [maintenanceTestMode] = React.useState(cookies.get('maintenancetest') !== undefined)

    return (
        <>
            <MapScriptLoader />
            <NavBarMenuContainer t={props.t} i18n={props.i18n} />
            {maintenanceTestMode && <MaintenanceTestBanner />}

            <div className="container-md body-content px-2 pb-3">
                {props.isLoggedIn ? <Link className="btn srp-btn btn-blue float-right mt-4 mr-3 d-none d-md-block" to="/logout">{props.t("Log out")}</Link> : null}
                {props.children}
            </div>

            <SrpSnackbar />
            <FooterContainer />
            {props.isLoggedIn ? <PrimaryContactInfoReminderModal /> : null }
        </>
    )
}

let mapStateToProps = state => {
    return {
        isLoggedIn: state.login.isLoggedIn,
        displayedLanguage: state.myAccount.displayedLanguage
    }
}

MainLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isLoggedIn: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

export default withTranslation("mainLayout")(connect(mapStateToProps)(MainLayout))