import { solarNonprofitFormConstants } from '../../constants/solar-nonprofit-constants'

import format from '../format'

const solarNonprofitMapper = {
    createSolarNonprofitModel,
    mapDonationAmountToServer,
    mapServerToDonationAmount
}

function mapDonationAmountToServer(donation){
    switch(donation) {
        case solarNonprofitFormConstants.DONATION_AMT_NONE: return 0
        case solarNonprofitFormConstants.DONATION_AMT_3: return 1
        case solarNonprofitFormConstants.DONATION_AMT_6: return 2
        case solarNonprofitFormConstants.DONATION_AMT_9: return 3
        case solarNonprofitFormConstants.DONATION_AMT_12: return 4
        case solarNonprofitFormConstants.DONATION_AMT_UNENROLL: return 5
    }
}

function mapServerToDonationAmount(serverCode){
    let donationBlock = parseInt(serverCode)
    switch(donationBlock) {
        case 1: return solarNonprofitFormConstants.DONATION_AMT_3
        case 2: return solarNonprofitFormConstants.DONATION_AMT_6
        case 3: return solarNonprofitFormConstants.DONATION_AMT_9
        case 4: return solarNonprofitFormConstants.DONATION_AMT_12
        default: return solarNonprofitFormConstants.DONATION_AMT_NONE
    }
}

function createSolarNonprofitModel(props, formValues) {

    let model = {
        customerInfoModel: {
            firstName: props.customerNames.firstName,
            middleName: props.customerNames.middleName,
            lastName: props.customerNames.lastName,
            emailAddress: formValues.emailAddress,
            phoneNumber: format.formatPhoneNumber(props.phoneNumber)
        },
        businessInfoModel: {
            ...props.businessInfo,
            emailAddress: formValues.emailAddress,
            phoneNumber: format.formatPhoneNumber(props.phoneNumber)
        },
        serviceAddress: {
            streetAddress: props.serviceAddress.streetAddress,
            streetAddressLineTwo: '',
            state: props.serviceAddress.state,
            city: props.serviceAddress.cityName,
            zipCode: props.serviceAddress.zipCode
        },
        customerName: props.isCommercial ? props.businessInfo.businessName : props.customerNames.displayName,
        accountNumber: props.selectedBillAccount,
        solarNonprofitDonation: mapDonationAmountToServer(formValues.selectedDonationAmount),
        isResidential: !props.isCommercial,
        preferredLanguage: props.formLanguage === 'es' ? 'es-MX' : 'en-US',
        solarNonprofitAction: formValues.selectedDonationAmount === solarNonprofitFormConstants.DONATION_AMT_UNENROLL
                                ? solarNonprofitFormConstants.SOLAR_NONPROFIT_UNENROLL
                                : formValues.formType
    }

    return model
}

export default solarNonprofitMapper