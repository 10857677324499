import React from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { DocumentTitle } from '../../../srp_modules/document-title'

import HealthyForestFormik from './healthy-forest-formik'

import AccountInfoBarContainer from '../../myaccount_header/info_bar/account-info-bar-container'
import CallAnytime from '../../myaccount_header/call-anytime'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getCustomerNames, getServiceAddress, getPhoneNumber, getBusinessInfo } from '../../../actions/auth/bill_account/bill-account-actions'
import { submitHealthyForestForm } from '../../../actions/auth/forms/healthy-forest-actions'

import healthyForestMapper from '../../../srp_modules/forms/healthy-forest-mapper'
import { healthyForestFormConstants } from '../../../constants/healthy-forrest-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import { withRouter } from '../../../srp_modules/with-router'
import utils from '../../../srp_modules/utils'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class HealthyForestSignUpContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialChangedState: false
        }

        this.submitHealthyForestForm = this.submitHealthyForestForm.bind(this)
        this.onStateChanged = this.onStateChanged.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedBillAccount === 0) {
            return
        }

        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.isCommercial &&
            (this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_INIT
                || this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_FAILURE)) {
            this.props.getBusinessInfoModel(this.props.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return

        if (this.props.customerNamesStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCustomerNamesInfo(this.props.selectedBillAccount)
        }

        if (this.props.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }

        if (this.props.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }

        if (this.props.isCommercial && this.props.businessInfoStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBusinessInfoModel(this.props.selectedBillAccount)
        }
    }

    submitHealthyForestForm(formValues, formActions) {
        let formDetails = healthyForestMapper.createHealthyForestModel(this.props, formValues)
        this.props.submitHealthyForestSignUp(formDetails, formActions)
    }

    onStateChanged() {
        this.setState({
            initialChangedState: true
        })
    }

    getFormDetails() {
        if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT || this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || (this.props.isCommercial
                && this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_INIT || this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)) {
            return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
        }

        const t = this.props.t
        const i18n = this.props.i18n
        const isSpanish = (i18n.language === "es")
        const supportNumber = utils.GetCustomerServicePhoneNumber(this.props.isCommercial, isSpanish)
        const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(this.props.isCommercial, isSpanish)
    
        return (<div className="row">
            <div className="col-lg-6 col-12">
                <div className="srp-card-header" id="topOfHealthyForestForm">
                    {this.props.submitHealthyForestFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                        ? t("SRP Healthy Forest Initiative Sign-Up")
                        : t("SRP Healthy Forest Initiative Sign-Up request submitted")
                    }
                </div>
                <HealthyForestFormik
                    customerName={this.props.isCommercial ? this.props.businessInfo.businessName : this.props.customerNames.displayName}
                    formattedBillAccount={format.formatBillAccountNumber(this.props.selectedBillAccount)}
                    serviceAddress={this.props.serviceAddress}
                    formattedPhoneNumber={format.formatPhoneNumber(this.props.phoneNumber)}
                    emailAddress={this.props.customerEmailAddress}
                    healthyForestDonationAmt={this.props.healthyForestDonationAmt}
                    submitHealthyForestFormStatus={this.props.submitHealthyForestFormStatus}
                    formType={healthyForestFormConstants.HEALTHY_FOREST_SIGNUP}
                    submitHealthyForestForm={this.submitHealthyForestForm}
                    initialChangedState={this.state.initialChangedState}
                    onStateChanged={this.onStateChanged}
                    isCommercial={this.props.isCommercial}
                    t={t} i18n={i18n}
                />
            </div>

            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                <div className="srp-card-header">{t("Need help?")}</div>
                <div className="srp-card-body srp-orange-background">
                    {this.props.isCommercial
                        ? <div className="srp-card-details">
                            <p className="d-lg-block d-none mb-0">
                                {t("contact_info_commercial_desktop", {phone: supportNumberFmt})}
                            </p>
                            <p className="d-lg-none d-block mb-0">
                                <Trans i18nKey="contact_info_commercial_mobile" t={t}>
                                    Should you have questions, please contact the SRP Business Contact Center at
                                    <a href={"tel:"+supportNumber}>{{phone: supportNumberFmt}}</a>,
                                    Monday through Friday, 7 a.m.-5 p.m.
                                </Trans>
                            </p>
                        </div>
                        : <div className="srp-card-details">
                            <p className="d-lg-block d-none mb-0">
                                {t("contact_info_residential_desktop", {phone: supportNumberFmt})}
                            </p>
                            <p className="d-lg-none d-block mb-0">
                                <Trans i18nKey="contact_info_residential_mobile" t={t}>
                                    For questions please contact SRP Residential Customer Services at
                                    <a href={"tel:"+supportNumber}>{{phone: supportNumberFmt}}</a>,
                                    available to serve you 24 hours a day, seven days a week.
                                </Trans>
                            </p>
                        </div>
                    }

                </div>
            </div>
        </div>)
    }

    render() {
        const t = this.props.t

        return (
            <DocumentTitle title={t("SRP Healthy Forest Initiative Sign-Up | SRP Power")}>
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {t("Previous page")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("SRP Healthy Forest Initiative")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{t("SRP Healthy Forest Initiative")}</h4>

                        <AccountInfoBarContainer disableSelector={true} />

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{t("Previous page")}</button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{t("SRP Healthy Forest Initiative Sign-Up")}</span>
                            </h5>
                        </div>

                        {this.getFormDetails()}

                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        formLanguage: state.myAccount.preferredLanguage, 
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        customerEmailAddress: state.accountInfo.contact.primaryEmail,
        phoneNumber: state.accountInfo.billAccount.phoneNumber.bestPhone,
        phoneNumberStatus: state.accountInfo.billAccount.phoneNumberStatus,
        businessInfo: state.accountInfo.billAccount.businessInfo,
        businessInfoStatus: state.accountInfo.billAccount.businessInfoStatus,
        ...state.healthyForest
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getCustomerNamesInfo: (billAccount) => {
            dispatch(getCustomerNames(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        getBusinessInfoModel: (billAccount) => {
            dispatch(getBusinessInfo(billAccount))
        },
        submitHealthyForestSignUp: async (formDetails, formActions) => {
            const res = await dispatch(submitHealthyForestForm(formDetails))
            const submitSuccessfully = (res.payload?.success === true)
            formActions.setStatus({submitError: !submitSuccessfully})
            formActions.setSubmitting(false)
        }
    }
}

HealthyForestSignUpContainer.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    selectedBillAccount: PropTypes.number,
    isCommercial: PropTypes.bool,
    formLanguage: PropTypes.string,

    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    getCustomerNamesInfo: PropTypes.func,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,
    customerEmailAddress: PropTypes.string,

    serviceAddressStatus: PropTypes.string,
    serviceAddress: PropTypes.object,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    phoneNumber: PropTypes.string,
    getBestPhone: PropTypes.func,

    businessInfo: PropTypes.object,
    businessInfoStatus: PropTypes.string,
    getBusinessInfoModel: PropTypes.func,

    healthyForestDonationAmt: PropTypes.string,
    submitHealthyForestFormStatus: PropTypes.string,
    submitHealthyForestSignUp: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("healthyForest")(withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthyForestSignUpContainer)))